<template>
    <lassoLayout class="page-login">
        <div class="page_form_layout full_page">
            <v-container>
                <transition name="slide-fade" mode="out-in">
                    <div v-if="!showRegistration" key="login" class="wrap_form_layout">
                        <h2 class="login">Log in!</h2>
                        <div class="facebook_connect" v-ripple @click="showComingSoonDialog = true">
                            <span class="facebook_icon"></span>
                            Connect with facebook
                        </div>
                        <div class="or">
                            <span>Or</span>
                        </div>
                        <form action="#" class="form">
                            <div class="email">
                                <label>Email</label>
                                <input autofocus type="email" v-model="email" tabindex="1" v-on:keyup.enter="logIn" @blur="$v.email.$touch()">
                                <div class="error-msg" v-if="!$v.email.$error && !$v.email.maxLength">Email must be 128 characters or less</div>
                                <div class="error-msg" v-if="$v.email.$error">Please enter a valid email address</div>
                            </div>
                            <div class="password">
                                <div>
                                    <label>Password</label>
                                    <button type="button" class="show_password" @click="switchVisibility('password')"
                                            v-html="passwordType === 'password'? 'Show' : 'Hide'"></button>
                                </div>
                                <input :type="passwordType" v-model="password" tabindex="2" v-on:keyup.enter="logIn">
                                <div class="error-msg" v-if="!$v.password.$error && !$v.password.maxLength">Password must be 128 characters or less</div>
                            </div>
                        </form>
                        <div class="message_error">
                            <template v-if="LOGIN_STATUS.success === false">
                                {{LOGIN_STATUS.message}}
                            </template>
                        </div>

                        <button tabindex="3" v-if="email && password && !$v.email.$invalid && !$v.password.$invalid" 
                                type="button" class="log_in c2a_btn" v-ripple @click="logIn" 
                                :class="{'loading': loader}" :disabled="loader">
                            <v-progress-circular :width="2" indeterminate></v-progress-circular>
                            Log in
                            <span class="arrow">
                                <span class="line_svg"></span>
                                <svg width="7" height="10" viewBox="0 0 7 10" fill="none">
                                    <path d="M1.5 1.5L5 5L1.5 8.5" stroke="white" stroke-width="2" stroke-linecap="square"/>
                                </svg>
                            </span>
                        </button>
                        <button v-else type="button" class="log_in c2a_btn" v-ripple disabled>Log in</button>
                        <div class="recovery_password">
                            <router-link :to="{name: 'forgot-password'}" tabindex="4">Recover password</router-link>
                        </div>
                        <a href="#" class="new_account" tabindex="5" @click.prevent="toggleForm">New Account</a>
                    </div>

                    <div v-else key="register" class="wrap_form_layout">
                        <h2 class="join_the_network">Join the Network!</h2>
                        <div class="facebook_connect" v-ripple @click="showComingSoonDialog = true">
                            <span class="facebook_icon"></span>
                            Connect with facebook
                        </div>
                        <div class="or">
                            <span>Or</span>
                        </div>
                        <div class="form">
                            <form action="#" class="form">
                                <div class="email">
                                    <label for="">Email</label>
                                    <input autofocus type="email" v-model="email" tabindex="1" v-on:keyup.enter="signUp">
                                    <div class="error-msg" v-if="!$v.email.$error && !$v.email.maxLength">Email must be 128 characters or less</div>
                                </div>
                                <div class="password">
                                    <div>
                                        <label for="">Create Password</label>
                                        <button type="button" class="show_password" @click="switchVisibility('password')"
                                                v-html="passwordType === 'password'? 'Show' : 'Hide'"></button>
                                    </div>
                                    <input :type="passwordType" v-model="password" @keyup="passwordOnKeyDown" tabindex="1" v-on:keyup.enter="signUp">
                                    <div class="error-msg" v-if="!$v.password.$error && !$v.password.maxLength">Password must be 128 characters or less</div>
                                </div>
                            </form>
                        </div>
                        <div class="password_complexity">
                            <span class="label">Password must include:</span>
                            <div class="wrap_must_symbols">
                                <div :class="[ {active: passComplexity.upperCase}, 'item_' ]">ABC</div>
                                <div :class="[ {active: passComplexity.lowerCase}, 'item_']">abc</div>
                                <div :class="[ {active: passComplexity.nonWordChars}, 'item_']">@#&</div>
                                <div :class="[ {active: password.length > 8}, 'item_']">
                                    8+ Characters
                                </div>
                            </div>
                        </div>
                        <div class="message_error" v-if="!loader">
                            {{REGISTER_ERROR_MESSAGE}}
                            <template v-if="REGISTER_STATUS !== 'success' && !REGISTER_ERROR_MESSAGE">
                                {{ message }}
                            </template>
                        </div>

                        <button v-if="!$v.email.$invalid && passwordValid" type="button" class="sign_up c2a_btn" 
                                v-ripple @click="signUp" :class="{'loading': loader}" :disabled="loader">
                            Sign Up
                            <v-progress-circular :width="2" indeterminate></v-progress-circular>
                            <span class="arrow">
                                <span class="line_svg"></span>
                                <svg width="7" height="10" viewBox="0 0 7 10" fill="none">
                                    <path d="M1.5 1.5L5 5L1.5 8.5" stroke="white" stroke-width="2" stroke-linecap="square"/>
                                </svg>
                            </span>
                        </button>
                        <button v-else type="button" class="sign_up c2a_btn" disabled>Sign Up</button>

                        <div class="already_account">
                            Already have an account?
                            <a href="#" @click.prevent="toggleForm">Log in!</a>
                        </div>
                    </div>
                </transition>
                <ComingSoonDialog v-if="showComingSoonDialog" v-model="showComingSoonDialog"></ComingSoonDialog>
            </v-container>
        </div>
    </lassoLayout>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import lassoLayout from '@/layouts/lassoLayout.vue';
import {email, maxLength, minLength, required} from "vuelidate/lib/validators";

const ComingSoonDialog = () => import('@/components/dialogs/comingSoonDialog.vue');

export default {
    name: 'login',
    components: {
        lassoLayout,
        ComingSoonDialog
    },
    data() {
        return {
            loader: false,
            showComingSoonDialog: false,
            showRegistration: false,
            email: '',
            password: '',
            passwordType: 'password',
            passComplexity: {
                lowerCase: false,
                upperCase: false,
                nonWordChars: false
            },
            passwordValid: false,
            message: ''
        }
    },
    validations: {
        email: {
            email,
            required,
            maxLength: maxLength(128)
        },
        password: {
            required,
            maxLength: maxLength(128)
        },
    },
    watch: {
        email() {
            this.$v.$reset();
            this.message = '';
        },
        password() {
            this.message = '';
        }
    },
    computed: {
        ...mapGetters(['AUTH_DATA', 'LOGIN_STATUS', 'NEED_LOGIN_REDIRECT_PATH', 'REGISTER_STATUS', 'REGISTER_ERROR_MESSAGE']),
    },
    mounted() {
        if (this.AUTH_DATA.accessToken) {
            this.$router.push({name: 'explore'});
        }
    },
    methods: {
        ...mapActions(['LOGIN', 'CLEAN_EXPLORE_GRID_ALBUMS', 'GET_BASKET', 'REGISTER']),
        switchVisibility() {
            this.passwordType = this.passwordType === 'password' ? 'text' : 'password';
        },
        toggleForm() {
            this.email = '';
            this.password = '';
            this.message = '';
            this.showRegistration = !this.showRegistration;
        },
        passwordOnKeyDown() {
            this.passComplexity.nonWordChars = !!this.password.match(/[!@#$%^&*().,;:{}_+=<>?|~-]/);
            this.passComplexity.upperCase = !!this.password.match(".*[A-Z]");
            this.passComplexity.lowerCase = !!this.password.match(".*[a-z]");

            this.passwordValid = this.passComplexity.lowerCase === true && 
                               this.passComplexity.upperCase === true && 
                               this.passComplexity.nonWordChars === true &&
                               this.password.length >= 8;
        },
        logIn() {
            if (this.email && this.password && !this.loader) {
                this.loader = true;

                const userData = {
                    email: this.email,
                    password: this.password
                };
                this.LOGIN(userData)
                    .then(() => {
                        if (this.LOGIN_STATUS.success === true) {
                            this.CLEAN_EXPLORE_GRID_ALBUMS();
                            if (this.AUTH_DATA.verify === true && this.AUTH_DATA.firstLogin === false) {
                                this.GET_BASKET();

                                let addAlbumToFavorites = localStorage.getItem('add_album_to_favorites');
                                if (addAlbumToFavorites) {
                                    this.$store.dispatch('ADD_ALBUM_TO_FAVORITES', {'music_release_id': addAlbumToFavorites})
                                        .then(() => {
                                            localStorage.removeItem('add_album_to_favorites');
                                        });
                                }
                                let addTrackToFavorites = JSON.parse(localStorage.getItem('add_track_to_favorites'));
                                if (addTrackToFavorites) {
                                    let params = {
                                        'song_id': addTrackToFavorites.song.id,
                                        'album_id': addTrackToFavorites.album.id
                                    }
                                    this.$store.dispatch('ADD_TRACK_TO_FAVORITES', params)
                                        .then(() => {
                                            localStorage.removeItem('add_track_to_favorites');
                                        });
                                }

                                if (this.NEED_LOGIN_REDIRECT_PATH) {
                                    this.$router.push(this.NEED_LOGIN_REDIRECT_PATH.substring(1));
                                    return;
                                }

                                this.$router.push(this.$route.query.redirect || '/');
                            }
                            if (this.AUTH_DATA.verify === false) {
                                this.$router.push({name: 'email-verification'});
                            }
                            if (this.AUTH_DATA.firstLogin === true && this.AUTH_DATA.verify === true) {
                                this.$router.push({name: 'profile-basics'});
                            }
                        }

                        if (this.LOGIN_STATUS.message === 'not_verified_email') {
                            this.$router.push({name: 'email-verification'});
                        }
                    })
                    .finally(() => this.loader = false);
            }
        },
        signUp() {
            if (this.email && this.password && this.passwordValid === true && !this.loader) {
                this.loader = true;

                const userData = {
                    email: this.email,
                    password: this.password,
                    address: 'Planet Earth',
                    fullName: this.email.split('@')[0],
                }
                this.REGISTER(userData)
                    .then(() => {
                        if (this.REGISTER_STATUS === 'success') {
                            this.$router.push({name: 'email-verification'});
                        } else {
                            this.message = 'Error. Registration failed';
                        }
                    })
                    .catch(err => {
                        this.message = 'Error. Registration failed';
                        console.error(`signUp ${err}`);
                    })
                    .finally(() => this.loader = false);
            } else {
                this.message = 'Error. Registration failed';
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.page-login {
    padding-top: 20px;

    // Modern slide-fade transition
    .slide-fade-enter-active {
        transition: all 0.3s ease-out;
    }

    .slide-fade-leave-active {
        transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
    }

    .slide-fade-enter-from {
        transform: translateX(20px);
        opacity: 0;
    }

    .slide-fade-leave-to {
        transform: translateX(-20px);
        opacity: 0;
    }

    // Form styling
    .form {
        input {
            background-color: #040A18;
            color: white;
            border: 1px solid #00a6ffb1;
            border-radius: 4px;
            padding: 10px;
            width: 100%;
            margin-top: 5px;
            font-size: 16px;

            // Remove browser defaults
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;

            // Handle autofill
            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus,
            &:-webkit-autofill:active {
                -webkit-box-shadow: 0 0 0 30px #040A18 inset !important;
                -webkit-text-fill-color: white !important;
                caret-color: white !important;
            }

            &:focus {
                outline: none;
                border-color: #00a6ffb1;
                box-shadow: 0 0 5px #040A18;
            }

            &::placeholder {
                color: rgba(255, 255, 255, 0.6);
            }
        }
    }

    // Add styles for active sign up button with Safari support
    .wrap_form_layout button.sign_up.c2a_btn:not([disabled]) {
        background: #00ff84a2 !important;
        background-color: #00ff84a2 !important;
        -webkit-background-color: #00ff84a2 !important;
        background-image: none !important;
        -webkit-background-image: none !important;
        
        // Ensure proper rendering in Safari
        -webkit-appearance: none;
        -webkit-transform: translateZ(0);
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        
        &:hover {
            background: lighten(#00ff84a2, 10%) !important;
            background-color: lighten(#00ff84a2, 10%) !important;
            -webkit-background-color: lighten(#00ff84a2, 10%) !important;
        }

        &:disabled {
            opacity: 0.7;
            cursor: not-allowed;
        }
    }

    // Add disabled state styles for login button
    .wrap_form_layout button.log_in.c2a_btn:disabled {
        opacity: 0.7;
        cursor: not-allowed;
    }
}
</style>
